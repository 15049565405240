<template>
	<main class="page-container container">
		<div class="section__top mb-30">
			<div class="section__top-left">
				<AppText :size="isMobileSmall ? 20 : isMobile  ? 24 : 26" weight="600"
						 :line-height="isMobileSmall ? 22 : isMobile ? 28 : 34">
					{{ $t('suppliers') }}
				</AppText>
			</div>
		</div>

		<div class="section__body mb-80">
			<div :class="isMobileSmall ? 'mb-20' : 'mb-50'">
				<BlockWrap
					:count="isMobileSmall ? 1 : isDesktopSmall ? 2 : isDesktopMedium ? 3 : 4"
					offset-y="20"
					offset-x="20"
				>
					<template v-for="item in allSuppliers" class="">
						<AppCard
							@onVote="voteCompany($event, item.id)"
							class="supplier-card"
							:title="$options.filters.translate(item.companyName)"
							:logo="item.photo"
							:description="item.description"
							:item="item"
							btn/>
					</template>
				</BlockWrap>
			</div>

			<pagination :total="this.total" :perPage="this.query.take" @paginate="pagination($event)">
			</pagination>
		</div>
	</main>
</template>

<script>
import AppText from "../../components/shared-components/AppText";
import BlockWrap from "../../components/shared-components/BlockWrap";
import AppPagination from "@/components/shared-components/AppPagination";
import Pagination from "@/components/shared-components/Pagination";
import AppCard from "../../components/shared-components/AppCard";

export default {
	name: "index",
	components: {
		Pagination,
		BlockWrap,
		AppText,
		AppCard,
		AppPagination
	},
	data() {
		return {
			query: {
				skip: 0,
				take: 10
			},
			total: 0,
			adminUrl: process.env.VUE_APP_BASE_URL_DOMAIN,
			allSuppliers: []
		}
	},
	methods: {
		load() {
			this.$api.get('/api-client/Client/GetAllCompany', {params: this.query})
				.then(
					(response) => {
						this.allSuppliers = response.data.result.data
						this.total = response.data.result.total
					}
				)
		},
		voteCompany(status, id) {
			let params = {
				id,
				status
			}
			this.$api.post('/api-client/Client/AddUpdateCompanyVoute', params).then(
				(response) => {
					this.$notification.success(this.$i18n.t("notification.success"));
					this.load()
				}
			)
		},
		pagination(skip) {
			this.query.skip = skip;
			this.load();
		},
	},
	created() {
		this.load();
	}
}
</script>

<style lang="scss" scoped>
.news {
	&__holder {
	}

	&__item {
		display: flex;
		width: 100%;
		transition: .5s;
	}

	&__photo {
		min-width: 208px;
		height: 138px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 6px;
		overflow: hidden;

		img {
			max-height: 138px;
			height: 100%;
			max-width: 208px;
			width: 100%;
			object-fit: cover;
			transition: .5s;
		}
	}

	&__content {
		flex-grow: 1;
	}

	&__link {
		color: #38B1F5;
		font-weight: 600;
		font-size: 14px;
		line-height: 18px;
		position: relative;
		transition: .5s;

		&::before {
			content: '';
			position: absolute;
			height: 2px;
			width: 0;
			background-color: #38B1F5;
			transition: .5s;
			top: calc(100% + 2px);
		}

		&:hover {
			&::before {
				width: 100%;
			}
		}
	}
}


@media(max-width: 768px) {
	.news {
		&__item {
			flex-wrap: wrap;
		}

		&__photo {
			margin-bottom: 20px;
		}
	}
}

@media(max-width: 450px) {
	.news {
		&__photo {
			max-width: 100%;
			width: 100%;
			max-height: 500px;
			position: relative;
			min-width: unset;
			margin-right: 0;

			img {
				width: 100%;
				border-radius: 6px;
				height: 100%;
			}
		}
	}
}
</style>
